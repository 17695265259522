
/* Contact */
.content-title h1{
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
}

.contact-explain{
    margin: 0 15%;
    font-family: my-font1;
}
.contact-explain span{
    font-size: 11px;
    color: red;
}


.contact-form{
    margin: 0 15%;
}

.text-areas{
    display: flex;
    font-family: my-font1;
}
.text-areas p{
    width: 30%;
    min-width: 160px;
}

.text-areas input{
    width: 40%;
    min-width: 160px;
    height: 80%;
    margin: 16px 0;
}
.text-areas textarea{
    width: 50%;
    min-width: 160px;
    height: 150%;
    min-height: 100px;
    margin: 16px 0;
}

/* .text-areas input, .text-areas textarea{
    margin: 0 10%;
} */

form p span{
    font-size: 11px;
    color: red;
}

.input-area{
    width: 300px;
}

.textarea-message{
    width: 350px;
    height: 100px;
}

.alert-message{
    margin: 0px 10px;
    font-size: 11px;
    color: red;
}

.submit-button{
    width: 80px;
    height: 40px;
    border-radius: 10px;
    border-width: 0px;
    color: white;
    background-color: rgb(143, 143, 143);
    margin: 20px 0px;
    font-size: 16px;
    font-family: my-font1;
}

.submit-button:hover{
    background-color: rgb(77, 77, 77);
    transform: scale(0.95, 0.95);
    transition: all 0.8s;
    cursor: pointer;
}

iframe{
    width: 100%;
    height: 50vh;
    border: 0;
}

#not-overlay{
    display: none;
}

#overlay{
    /*　画面全体を覆う設定　*/
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.5);
    /*　画面の中央に要素を表示させる設定　*/
    display: flex;
    align-items: center;
    justify-content: center;
}

#modal-content{
    z-index:2;
    width:50%;
    padding: 1em;
    background:#fff;
    border-radius: 15px;
    text-align: center;
}

.modal-close{
    width: 60px;
    height: 30px;
    border-radius: 5px;
    border-width: 0px;
    color: white;
    background-color: rgb(143, 143, 143);
}
.modal-close:hover{
    background-color: rgb(77, 77, 77);
    cursor: pointer;
}



@media screen and (max-width: 420px) {
    .contact-explain{
        margin: 0 5%;
    }
    .contact-form{
        margin: 0px 5%;
    }
    .input-area{
        width: 150px;
    }
    .textarea-message{
        width: 200px;
    }
    iframe{
        height: 90vh;
    }
    .text-areas{
        display: block;
    }
    .text-areas p{
        margin-bottom: 0px;
    }
    .text-areas input{
        margin-top: 0px;
        margin-bottom: 0px;
        min-width: 200px;
    }
    .text-areas textarea{
        margin-top: 0px;
        margin-bottom: 0px;
        min-width: 250px;
    }
}

.sp-body{
    margin: 0 10%;
}