/* HeaderPc */
.video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.video-pc-item{
    height: 50px;
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: 0;
    animation: pc-bike 30s infinite;
}
@keyframes pc-bike {
    0% {
        left: -10%;
    }
    100% {
        left: 150%;
    }
}

.header-pc-fix{
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 1000;
}

.header-pc{
    font-family: my-font5;
    margin: 0 auto;
    width: 80%;
    height: 70px;
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
}

.header-pc-title{
    flex: 2;
    font-size: 35px;
    margin: 0px;
    padding: 15px 15px 15px 0px;
    z-index: 9999;
    font-family: my-font4;
}


.header-pc-title a{
    font-family: my-font5;
    font-weight: bolder;
    list-style: none;
    color: black;
    text-decoration: none;
}


.header-menu {
    height: 100%;
    flex: 1;
    font-size: 15px;
    z-index: 9999;
    min-width: 400px;
}

.list-menus {
    height: 100%;
    display: flex;
    text-align: center;
    margin: 0px 10%;
    /* padding: 0px 20%; */
}

.list-menu{
    min-width: 90px;
    font-size: larger;
    flex: 1;
    justify-content: center; /* 横方向（左右）の中央揃え */
    align-items: center; /* 縦方向（上下）の中央揃え */
    list-style: none;
    margin: auto;
}



.list-menu a{
    display: block;
    text-decoration: none;
    text-align: center;
    color: #000;
    padding: 15px 10px;
    margin: auto 5px;
    transition: all 0.8s;
}

.list-menu a:hover{
    /* text-decoration: underline; */
    /* background-color: antiquewhite; */
    transform: scale(0.8, 0.8);
    transition: all 0.8s;
    cursor: pointer;
}

.header-img{
    width: 100%;
    margin: 0;
}

#about-space,#worktop-space,#contact-space{
    height: 70px;
}


/* BodyPc */
.pc-body{
    font-family: my-font5;
    margin: 0 10%;
}


.footer-img{
    width: 100%;
    margin: 10% 0 0 0;
}