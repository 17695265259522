/* BodyPc */
/* .pc-body{
    font-family: my-font5;
    margin: 0 10%;
} */

/* BodySp */
.sp-body{
    font-family: my-font5;
}



.about-title h1{
    font-weight: bolder;
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
}

.about-content{
    display: flex;
}

.about-img-area{
    flex: 1;
    /* position: relative; */
}

.about-img{
    /* margin-left: 10px; */
    position: relative;
    /* width: 300px;
    height: 200px; */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.about-img::after{
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 0 10px 10px white;
}

/* #FFF; */

.about-image{
    width: 100%;
}

.about-text{
    flex: 2;
}

.about-text h3{
    margin-top: 0px;
    margin-left: 5%;
    margin-bottom: 0px;
}

.about-text p{
    margin: 15px 5% 40px 10%;
    font-size: small;
    font-family: my-font1;
}

@media screen and (max-width: 420px) {
    .about-title h1{
        /* margin-left: 15%; */
        font-family: my-font5;
        font-weight: lighter;
    }
    .about-content {
        display: unset;
    }
    .about-img{
        width: 100%;
        margin: 0px auto;
    }
    .about-image{
        width: 100%;
        margin-left: 0px;
        margin-bottom: 10px;
    }

}