/* HeaderSp */
.video{
    position: absolute;
    top: 0;
    left: 0;
}

.video-sp-item{
    height: 40px;
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: -1;
    animation: sp-bike 15s infinite;
}
@keyframes sp-bike {
    0% {
        left: -100px;
    }
    100% {
        left: 450px;
    }
}

.header-sp-fix{
    position: fixed;
    background-color: white;
    width: 100%;
    z-index: 1000;
}


.header-sp{
    font-family: my-font5;
    height: 50px;
    margin: auto 0px;
    display: flex;
    justify-content: flex-end;
    padding: 5px 20px;
    z-index: 999;
    background-color: white;
    position: relative;
}

.header-sp-title{
    margin: auto 0px;
}

.header-sp-title a{
    list-style: none;
    color: black;
    text-decoration: none;
    justify-content: center;

}

/* ToggleButton */
.toggleButton {
    display: flex;
    width: 40px;
    height: 40px;
    appearance: none;
    border: none;
    background: transparent;
    outline: none;
    border-radius: 0;
    letter-spacing: inherit;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    z-index: 2;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.toggleButton > span {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 0.5s;
}

.line-1 {
    transform: translate(0, -10px);
}

.line-2 {
    transform: translate(0, 0px);
}

.line-3 {
    transform: translate(0, 10px);
}

.toggleButton[aria-expanded="true"] > .line-1 {
    transform: translate(0, 0) rotate(-45deg);
    transition: all 0.5s;
}

.toggleButton[aria-expanded="true"] > .line-2 {
    transform: translate(40px, 0px);
    transition: all 0.3s;
}

.toggleButton[aria-expanded="true"] > .line-3 {
    transform: translate(0, 0) rotate(45deg);
    transition: all 0.5s;
}


/* Navigation */
.navigation {
    display: none;
    align-items: center;
    justify-content:first baseline;
    flex-direction: column;
    padding: 5rem 2rem;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: 100vh;
    background-color: white;
}

.navigation[aria-hidden="false"] {
    display: flex;
    animation: navigation-open 0.5s 1;
}
@keyframes navigation-open {
    0% {
        transform: translateY(-200px);
    }
    100% {
        transform: translateY(0);
    }
}

/* .navigation[aria-hidden="true"] {
    animation: navigation-close 0.5s 1;
}
@keyframes navigation-close {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-200px);
        display: none;
    }
} */

.navigation > ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 0px;
    gap: 1rem 0;
}

.navigation > ul > li {
    align-items: center;
    text-align: center;
}

.nav,
.nav a {
    font-size: larger;
    list-style: none;
    color: black;
    text-decoration: none;
    padding: auto 10px;
    margin: auto -5px;
}

.active-menu{
    color: gray;
    text-decoration: underline
}

#about-space,#worktop-space,#contact-space{
    height: 60px;
}

/* BodySp */
.sp-body{
    font-family: my-font5;
}


.footer-img{
    width: 100%;
    margin: 10% 0 0 0;
}