

.not-found{
    height: 100vh;
    background-color: rgb(200, 200, 200);
    font-family: my-font1;
}

.not-found h1{
    margin: 0 auto;
    padding-top: 10%;
    text-align: center;
    font-size: 30px;
    font-weight: bolder;
}

.not-found p{
    margin: 10 auto;
    text-align: center;

}