
.worktop-title h1{
    text-align: center;
    margin-top: 10%;
    margin-bottom: 5%;
}

.work-links{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.work-link{
    max-width: 30%;
    padding: 10px;
    margin: 0px 5% 5% 5%;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 5px;
    text-decoration: none;
}

.work-links a:hover{
    transform: scale(0.95, 0.95);
    transition: all 0.95s;
    cursor: pointer;
}


.work-link img{
    width: 100%;
}

.work-text h2{
    font-family: 'Mure';
    font-size: medium;
    text-align: center;
    color: black;
    margin: 10px 0;
}
.work-text p{
    font-family: 'Mure';
    font-size: small;
    text-align: center;
    margin: 0;
    color: black;
}



@media screen and (max-width: 420px) {
    .work-link{
        max-width: 70%;
    }

}