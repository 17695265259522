

dialog{
    width: 280px;
    text-align: center;
    border-radius: 15px;
    border-color: rgb(143, 143, 143);
    border-style: ridge;
    background:#fff;
}

.modal-content{
    font-family: my-font1;
}


.modal-top-button{
    width: 100px;
    height: 40px;
    border-radius: 10px;
    border-width: 0px;
    color: white;
    background-color: rgb(143, 143, 143);
    margin: 20px 0px;
    font-size: 16px;
    font-family: my-font1;
}

.modal-top-button:hover{
    background-color: rgb(77, 77, 77);
    transform: scale(0.95, 0.95);
    transition: all 0.8s;
    cursor: pointer;
}


@media screen and (max-width: 420px) {
    /* dialog{
        min-width: 280px;

    } */
}